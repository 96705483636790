import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
//Components
import Layout from '../components/layout'

//Styles
import * as styles from '../styles/Frontpage.module.scss'

// const IndexPage = ({ data, pageContext: { locale } }) => {
class IndexPage extends Component {
  constructor() {
    super()
    this.state = {
      nav: {},
    }
  }

  componentDidMount() {
    const nav = window.navigator
    this.setState({
      nav,
    })
  }
  render() {
    const {
      data,
      pageContext: { locale },
    } = this.props

    return (
      <Layout locale={locale}>
        <div className="container">
          <div className={styles.cards}>
            {data.allDatoCmsFrontpage.edges.map(info => {
              return (
                <div className={styles.card} key={info.node.id}>
                  <GatsbyImage alt="" image={info.node.photo.gatsbyImageData} />
                  <div className={styles.innerCard}>
                    <div>
                      <h3>
                        <Link to={`/${locale}/${info.node.path}/`}>
                          {info.node.headline}
                        </Link>
                      </h3>
                      <h4>{info.node.subline}</h4>
                      <span className={styles.underline} />
                      <p>{info.node.paragraph}</p>
                      <Link
                        className={styles.button}
                        to={`/${locale}/${info.node.path}/`}
                      >
                        <FormattedMessage id="read_more" />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export const frontPageQuery = graphql`
  query frontPAgeQuery($locale: String!) {
    allDatoCmsFrontpage(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          locale
          headline
          subline
          path
          paragraph
          photo {
            gatsbyImageData(width: 600, height: 400, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
